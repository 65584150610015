.square{
  height: 100px;
  width: 100px;
  background: white;
  font-size: -webkit-xxx-large;
}

.form-custom{
  display: flex;
}
